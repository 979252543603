import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getCategories, getSubCategory } from "../services/categoryService";
import api from "../config/axiosConfig";
import AuthContext from "../contexts/AuthContext";

export default function Navbar() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);

  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const categoriesRef = useRef();

  const profileDropdownRef = useRef();

  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);

  const toggleDropdownC = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleDropdownCm = () => {
    setDropdownOpen1(!isDropdownOpen1);
  };
  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        const categoriesWithSub = await Promise.all(
          categoriesData.map(async (category) => {
            const subcategories = await getSubCategory(category._id);
            return { ...category, subcategories };
          })
        );
        setCategories(categoriesWithSub);
        console.log(categories)
      } catch (error) {}
    };
    fetchCategories();
  }, []);

  const handleLogout = async () => {
    try {
      await api.post("/auth/logout");
      logout();
      navigate("/");
    } catch (err) {}
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoriesRef.current &&
        !categoriesRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setProfileDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <nav className="bg-white shadow sticky top-0 z-40">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-5">
          <div className="flex justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <NavLink
                className="text-3xl font-bold text-gray-800"
                style={{ fontFamily: "Playfair Display, serif" }}
                to="/"
              >
                Nectar
              </NavLink>
            </div>

            {/* Desktop NavLinks */}
            <div className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12 text-primary">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `inline-flex items-center text-sm font-medium ${
                    isActive ? "text-second" : "text-gray-700"
                  } hover:text-second`
                }
              >
                Home
              </NavLink>
              <div
                className="hidden md:ml-6 md:flex md:space-x-8"
                ref={categoriesRef}
              >
                <button
                  onClick={toggleDropdownC}
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-700 hover:text-second"
                >
                  Categories
                  <svg
                    className="ml-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.584l3.71-4.354a.75.75 0 111.14.976l-4.25 5a.75.75 0 01-1.14 0l-4.25-5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <NavLink
                to="/user/product-list"
                className={({ isActive }) =>
                  `inline-flex items-center text-sm font-medium ${
                    isActive ? "text-second" : "text-gray-700"
                  } hover:text-second`
                }
              >
                Products
              </NavLink>
              <NavLink
                to="/user/promotion-list"
                className={({ isActive }) =>
                  `inline-flex items-center text-sm font-medium ${
                    isActive ? "text-second" : "text-gray-700"
                  } hover:text-second`
                }
              >
                Promotions
              </NavLink>
              <NavLink
                to="/user/about-us"
                className={({ isActive }) =>
                  `inline-flex items-center text-sm font-medium ${
                    isActive ? "text-second" : "text-gray-700"
                  } hover:text-second`
                }
              >
                About Us
              </NavLink>
            </div>

            {/* User Profile and Mobile Menu */}
            <div className="flex items-center justify-center space-x-4 ">
              {user ? (
                <>
                  {/* User Profile */}
                  <div className="w-2/4 md:w-auto">
                    <button
                      ref={profileDropdownRef}
                      onClick={toggleProfileDropdown}
                      className="px-4 py-2 flex items-center rounded-full text-[#333] text-sm border border-gray-300 outline-none hover:bg-gray-100"
                    >
                      <img
                        src={user.profile.imagePath}
                        className="w-7 h-7 mr-3 rounded-full shrink-0"
                        alt="Profile"
                      />
<p className="hidden md:block ">{user.userName}</p>

                    </button>
                    {isProfileDropdownOpen && (
                      <ul
                        className="absolute mt-4 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-20 max-h-96 overflow-auto"
                        ref={profileDropdownRef}
                      >
                        <li className="py-2.5 px-5 hover:bg-gray-100 text-[#333] text-sm cursor-pointer">
                          <Link to="/user/profile">View profile</Link>
                        </li>
                        <li className="py-2.5 px-5 hover:bg-gray-100 text-[#333] text-sm cursor-pointer">
                          <button
                            className="w-full text-left"
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Favorites Icon */}
                  <NavLink
                    className="relative w-1/4 md:w-auto text-gray-700 hover:text-second flex justify-center"
                    to="/user/favorites"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                  </NavLink>

                  {/* Cart Icon */}
                  <NavLink
                    className="relative w-1/4 md:w-auto text-gray-700 hover:text-second flex justify-center"
                    to="/user/cart"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </NavLink>
                </>
              ) : (
                <Link
                  to="/login"
                  className="px-4 py-2 rounded-full text-[#333] text-sm border border-gray-300 outline-none hover:bg-gray-100"
                >
                  Log in
                </Link>
              )}
              <button
                onClick={toggleSidebar}
                className="md:hidden focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Sidebar (Mobile Menu) */}
        {isSidebarOpen && (
          <div className="md:hidden bg-white shadow-lg">
            <div className="px-4 py-5 space-y-4">
              <Link
                to="/"
                className="block text-sm font-medium text-gray-700 hover:text-second"
              >
                Home
              </Link>
              <Link
                to="/user/product-list"
                className="block text-sm font-medium text-gray-700 hover:text-second"
              >
                Products
              </Link>
              <Link
                to="/user/promotion-list"
                className="block text-sm font-medium text-gray-700 hover:text-second"
              >
                Promotions
              </Link>
              <Link
                to="/user/about-us"
                className="block text-sm font-medium text-gray-700 hover:text-second"
              >
                About Us
              </Link>
              <button
                onClick={toggleDropdownCm}
                className="block text-sm font-medium text-gray-700 hover:text-second"
              >
                Categories
              </button>
              {isDropdownOpen && (
                <div className="ml-4">
                  {categories.map((category) => (
                    <div key={category._id}>
                      <Link
                        to={`/user/product-list?category=${category._id}`}
                        className="block text-sm font-medium text-gray-700 hover:text-second"
                      >
                        {category.name}
                      </Link>
                      {category.subcategories.length > 0 && (
                        <div className="ml-4">
                          {category.subcategories.map((subcategory) => (
                            <Link
                              key={subcategory._id}
                              to={`/user/product-list?category=${subcategory._id}`}
                              className="block text-sm font-regular text-gray-500 hover:text-second"
                            >
                              {subcategory.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {isDropdownOpen && (
          <div
            className="absolute inset-x-0 top-full bg-white shadow-lg"
            ref={categoriesRef}
          >
            <div className="max-w-7xl mx-auto px-4 py-6 grid grid-cols-4 gap-8">
              {categories.map((category) => (
                <div key={category._id}>
                  <Link
                    to={`/user/product-list?category=${category._id}`}
                    className="block px-4 py-2 font-medium text-gray-700 hover:text-second"
                  >
                    {category.name}
                  </Link>
                  {category.subcategories.length > 0 && (
                    <div className="pl-4">
                      {category.subcategories.map((subcategory) => (
                        <Link
                          key={subcategory._id}
                          to={`/user/product-list?category=${subcategory._id}`}
                          className="block px-4 py-2 font-regular text-gray-500 hover:text-second"
                        >
                          {subcategory.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}
