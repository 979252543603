import React from 'react';
import ProductCard from './prodCard';

const BestSellingProducts = ({ bestSellingProducts, fallbackProducts }) => {
  const productsToDisplay = bestSellingProducts.length > 0
    ? bestSellingProducts
    : fallbackProducts;
  return (
    (bestSellingProducts.length > 0 || fallbackProducts.length > 0) && (
      <div >
      <h2 className="text-3xl font-bold  text-center text-gray-800 mb-12">
      {bestSellingProducts.length > 0
            ? "Best Selling Products"
            : "Featured Products"}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1">
          {productsToDisplay.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    )
  );
};

export default BestSellingProducts;
