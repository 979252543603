import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div className="relative m-4 w-150 max-w-xs overflow-hidden rounded-lg bg-white shadow-md">
      <Link to={`/user/product-details/${product.id}`}>
        <div
          className="w-120 h-60 bg-center bg-no-repeat rounded-t-lg"
          style={{
            backgroundImage: `url(${product.imagePath[0]})`,
            backgroundSize: "contain", // Ensures the entire image is visible
          }}
          alt={product.productName}
        />
      </Link>
      {/* Promotion Badge */}
      {product.promotion && (
        <span className="absolute top-2 left-2 w-24 translate-y-4 -translate-x-6 -rotate-45 bg-black text-center text-sm text-white">
          {product.promotion.discountRate}% Off
          {console.log(product)}
        </span>
      )}
      {/* In Stock/Out of Stock Badge */}
      <div
        className={`absolute top-2 right-2 w-24 translate-y-4  text-xs font-semibold text-white text-center ${
          product.stock ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {product.stock ? "In Stock" : "Out of Stock"}
      </div>
      <div className="mt-4 px-4 pb-5">
        <Link to={`/user/product-details/${product.id}`}>
          <h5 className="text-lg font-semibold tracking-tight text-slate-900 truncate">
            {product.productName}
          </h5>
        </Link>
        <div className="mt-2.5 mb-5 flex items-center">
          {/* Dynamic Rating */}
          <span className="mr-2 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
            {product.AVGrating}
          </span>
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              aria-hidden="true"
              className={`h-5 w-5 ${
                index < product.AVGrating ? "text-yellow-300" : "text-gray-300"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>
        <div className="flex items-center justify-between">
          <p>
            <span className="text-xl font-bold text-slate-900">
              {product.finalPrice.toFixed(2)} TND
            </span>
            {product.price !== product.finalPrice && (
              <span className="text-sm text-slate-900 line-through ml-2">
                {product.price.toFixed(2)} TND
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
